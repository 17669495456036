import Vue from 'vue'
import router from '@/router';

// axios
import axios from 'axios'


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.timeout = 30000;

if (window.localStorage.access_token && !!window.localStorage.access_token.trim()) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.access_token}`;
}


const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 5000,
  // headers: {'Authorization': `Bearer ${window.localStorage.access_token}`}
})

// console.log(axios.defaults.headers.common);

axios.interceptors.response.use(function (response) {
  // this below line should be commented
  // console.log(axios.defaults.headers.common);
  return response;
}, function (error) {
  if (error.response.status === 401) {
      router.push('/auth/login')
  } else {
      return Promise.reject(error);
  }
});

Vue.prototype.$http = axiosIns

export default axiosIns
