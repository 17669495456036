export default [
  {
    title: "داشبورد",
    route: "dashboard",
    icon: "HomeIcon",
  },
  // {
  //   title: "مدیران",
  //   route: "admins",
  //   icon: "UserIcon",
  // },
  {
    title: "منو ها",
    route: "menus",
    icon: "MenuIcon",
  },
  {
    title: "محتوای متنی",
    route: "text-content",
    icon: "FileTextIcon",
    children: [
      {
        title: "صفحات",
        route: "text-content",
      },
      {
        title: "ثبت صفحه جدید",
        route: "text-content-create",
      },
    ],
  },
  {
    title: "دسته بندی",
    route: "categories",
    icon: "GridIcon",
    children: [
      {
        title: "دسته بندی ها",
        route: "categories",
      },
      {
        title: "ثبت دسته بندی جدید",
        route: "categories-create",
      },
    ],
  },
  {
    title: "بازی ها",
    route: "games",
    icon: "ArchiveIcon",
    children: [
      {
        title: "لیست بازی ها",
        route: "games",
      },
      {
        title: "ثبت بازی جدید",
        route: "game-create",
      },
    ],
  },
  {
    title: "مدیریت فایل ها",
    route: "file-manager",
    icon: "FileIcon",
  },
  {
    title: "مدیریت نظرات",
    route: "comments",
    icon: "MessageSquareIcon",
  },
  {
    title: "مدیریت پیام ها",
    route: "messages",
    icon: "InboxIcon",
  },
  {
    title: "ویژگی ها",
    route: "attributes",
    icon: "TagIcon",
  },
  {
    title: "برچسب ها",
    route: "tags",
    icon: "TagIcon",
  },
];
